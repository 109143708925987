import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaArrowLeft, FaSearch, FaUser } from "react-icons/fa";
import ReactPaginate from "react-paginate";

const AllEmployees = () => {
  const location = useLocation();
  const data = location.state;
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedTimeShift, setSelectedTimeShift] = useState(null);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [timeShifts, setTimeShifts] = useState([]);
  const employeesPerPage = 25;

  useEffect(() => {
    // Extract all unique time shifts from the data
    const allTimeShifts = Array.from(
      new Set(Object.values(data).flat().map(employee => `${employee.dayStart} TO ${employee.dayEnd}`))
    );
    setTimeShifts(allTimeShifts);
  }, [data]);

  // Function to format the date
  const formatDate = (dateString) => {
    if (!dateString) return null; // Return null if dateString is empty

    const date = new Date(dateString);

    // Check if the resulting date is invalid
    if (isNaN(date.getTime())) {
      return null; // Return null if the date is invalid
    }

    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hour = parseInt(hours, 10);
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour; // Handle 12 AM
    return `${formattedHour}:${minutes} ${period}`;
  };
  const convertTo12HourFormat = (timeShift) => {
    const [start, end] = timeShift.split(" TO ");
    const formattedStart = formatTime(start);
    const formattedEnd = formatTime(end);
    return `${formattedStart} TO ${formattedEnd}`;
  };

  const handleBack = () => {
    window.history.back();
  };

  const handleTabClick = (department) => {
    setSelectedDepartment(department === "All" ? null : department);
  };

  const handleTimeShiftChange = (e) => {
    setSelectedTimeShift(e.target.value === "All" ? null : e.target.value);
  };

  useEffect(() => {
    // Flatten the data structure into an array of employees
    const allEmployees = Object.values(data).flat();

    // Filter employees based on search query, selected department, and selected timeshift
    const filtered = allEmployees.filter((employee) =>
      employee.employeeName.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (!selectedDepartment || employee.department === selectedDepartment) &&
      (!selectedTimeShift || (`${employee.dayStart} TO ${employee.dayEnd}` === selectedTimeShift))
    );
    setFilteredEmployees(filtered);
  }, [searchQuery, selectedDepartment, selectedTimeShift, data]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * employeesPerPage;
  const pageCount = Math.ceil(filteredEmployees.length / employeesPerPage);
  const currentEmployees = filteredEmployees.slice(
    offset,
    offset + employeesPerPage
  );

  return (
    <div className="min-h-screen px-4 py-8">
      <div className="container mx-auto">
        <button
          id="login"
          onClick={handleBack}
          className="flex items-center rounded-full text-white py-2 px-2 mb-4 bg-blue-950 "
        >
          <FaArrowLeft className="mr-2" />
          Go Back
        </button>

        <h2 className="text-3xl font-semibold mb-8 text-gray-800">
          All Employees Details
        </h2>

        {/* Search input field */}
        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Search by Name"
            className="w-full p-2 pl-10 rounded border border-gray-300 focus:outline-none focus:border-blue-900"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <FaSearch className="absolute top-2 left-3 text-gray-400" />
        </div>

        {/* Timeshift filter */}
        <div className="relative mb-4">
          <select
            onChange={handleTimeShiftChange}
            value={selectedTimeShift || "All"}
            className="w-1/8 p-2 rounded border border-gray-300 focus:outline-none focus:border-blue-900 absolute right-0 top-0 mr-2"
          >
            <option value="All">All Timeshifts</option>
            {timeShifts.map((timeShift, index) => (
              <option key={index} value={timeShift}>
                {convertTo12HourFormat(timeShift)}
              </option>
            ))}
          </select>
        </div>

        {/* Department tabs */}
        <div className="flex mb-4">
          {["All", ...Object.keys(data)].map((department) => (
            <button
              style={{
                backgroundColor: selectedDepartment === department ? "#172554" : "#F4F4F4",
                color: selectedDepartment === department ? "#FFFFFF" : "#000000"
              }}
              key={department}
              onClick={() => handleTabClick(department)}
              className={`mr-2 px-4 py-2 rounded shadow-lg focus:outline-none ${selectedDepartment === department
                ? "bg-blue-950 text-black"
                : " text-gray-800 hover:bg-blue-500 hover:text-black"
                }`}
            >
              {department}
            </button>
          ))}
        </div>

        {/* Employee table */}
        <div className="flex justify-between items-center bg-blue-950 p-6 rounded-lg mb-8 overflow-x-auto">
          {/* Table headers */}
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Image
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Name
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Date Of Joining
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Role
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Department
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Time Shift
          </div>
        </div>
        {currentEmployees.length === 0 ? (
          <div className="text-center text-gray-600">No employees found.</div>
        ) : (
          <>
            {/* Display filtered employees */}
            {currentEmployees.map((employee) => (
              <div
                key={employee.id}
                className="flex shadow-lg bg-white rounded-full mb-4 transition-transform transform hover:translate-y-1 overflow-x-auto"
              >
                {/* Employee details */}
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <FaUser className="h-8 w-8 text-gray-800" />
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="font-semibold text-md text-black">
                      {employee.employeeName}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="text-gray-800 text-md">
                      {formatDate(employee.dateOfJoining)}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="text-md text-gray-800 font-bold">
                      {employee.role}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="text-md text-black font-bold">
                      {employee.department}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="text-md text-black font-bold">
                      {employee.dayStart} TO {employee.dayEnd}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
};

export default AllEmployees;
