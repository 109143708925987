import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeProfilePage = () => {
  const location = useLocation();
  const { employee } = location.state;
  const [updatedEmployee, setUpdatedEmployee] = useState({ ...employee });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    // Check if any required field is empty
    const requiredFields = ['enrollNumber','employeeName', 'role', 'department', 'cnic', 'email', 'salary', 'dayStart', 'dayEnd'];
    for (const field of requiredFields) {
      if (!updatedEmployee[field]) {
        toast.error(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}.`);
        return;
      }
    }
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://backendmd.marsbpo.org/api/employees/update/${employee.id}`,
        updatedEmployee,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Employee profile updated successfully.");
    } catch (error) {
      console.error("Error updating employee:", error);
      toast.error("Failed to update employee. Please try again.");
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `https://backendmd.marsbpo.org/api/employees/delete/${employee.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Employee deleted successfully.");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error deleting employee:", error);
      toast.error("Failed to delete employee. Please try again.");
    }
  };

  const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };


  return (
    <>
      <ToastContainer />

      <div className="bg-gray-100">
        <div className="min-h-screen  px-8 py-16">
          <button
            onClick={() => window.history.back()}
            className="flex lg:ml-12  items-center rounded-full bg-blue-950 text-white py-2 px-2 mb-4"
          >
            <FaArrowLeft className="mr-2" />
            Go Back
          </button>
          <div className="max-w-4xl w-full rounded-lg overflow-hidden shadow-lg lg:ml-40 bg-white">
            <form onSubmit={handleUpdate} className="p-8">
              <h2 className="text-3xl font-bold text-gray-800 mb-8">
                Employee Profile
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    name="employeeName"
                    value={updatedEmployee.employeeName}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    Enroll Number
                  </label>
                  <input
                    type="text"
                    name="enrollNumber"
                    value={updatedEmployee.enrollNumber}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    Father Name
                  </label>
                  <input
                    type="text"
                    name="fatherName"
                    value={updatedEmployee.fatherName}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    Role
                  </label>
                  <input
                    type="text"
                    name="role"
                    value={updatedEmployee.role}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    Department
                  </label>
                  <input
                    type="text"
                    name="department"
                    value={updatedEmployee.department}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">Date of Joining</label>
                  <input
                    type="date"
                    name="dateOfJoining"
                    value={formatDateToISO(updatedEmployee.dateOfJoining)}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    CNIC
                  </label>
                  <input
                    type="text"
                    name="cnic"
                    value={updatedEmployee.cnic}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={updatedEmployee.email}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    Emergency Contact No.
                  </label>
                  <input
                    type="text"
                    name="emergencyContact"
                    value={updatedEmployee.emergencyContact}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    Salary
                  </label>
                  <input
                    type="text"
                    name="salary"
                    value={updatedEmployee.salary}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    Day Start
                  </label>
                  <input
                    type="time"
                    name="dayStart"
                    value={updatedEmployee.dayStart}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-blue-500 mb-2">
                    Day End
                  </label>
                  <input
                    type="time"
                    name="dayEnd"
                    value={updatedEmployee.dayEnd}
                    onChange={handleInputChange}
                    className="border border-gray-300 focus:outline-none w-full py-2 px-3 rounded-lg"
                  />
                </div>
                
              </div>
              <div className="flex justify-between mt-8">
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 focus:outline-none"
                >
                  Update Profile
                </button>
                <button
                  onClick={handleDelete}
                  className="bg-red-500 text-white px-6 py-3 rounded-full hover:bg-red-600 focus:outline-none"
                >
                  Delete Employee
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeProfilePage;
