import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaUser, FaArrowLeft } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { FaEdit } from "react-icons/fa";
import UpdateAttendance from "./UpdateAttendance";

const WorkFromHomeEmployees = () => {
  const location = useLocation();
  const { workFromHomeEmployees } = location.state;

  // State for pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const employeesPerPage = 25;

  // Function to handle page change
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Filter out the employees with undefined status
  const validWorkFromHomeEmployees = workFromHomeEmployees.filter(
    (employee) => employee.status === "Work From Home" || "WFH"
  );

  // Extract unique department names
  const departments = [
    ...new Set(validWorkFromHomeEmployees.map((employee) => employee.department)),
  ];

  // Function to handle department filter change
  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setPageNumber(0); // Reset page number when changing department
  };

  // Filter employees based on selected department
  const filteredEmployees =
    selectedDepartment === "All"
      ? validWorkFromHomeEmployees
      : validWorkFromHomeEmployees.filter(
        (employee) => employee.department === selectedDepartment
      );

  // Function to filter employees by name
  const filteredEmployeesByName = filteredEmployees.filter((employee) =>
    employee.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the index of the first and last employee to display on the current page
  const startIndex = pageNumber * employeesPerPage;
  const endIndex = startIndex + employeesPerPage;
  const displayedEmployees = filteredEmployeesByName.slice(
    startIndex,
    endIndex
  );

  // Function to handle click on update icon button
  const handleUpdateClick = (employee) => {
    setSelectedEmployee(employee);
    setIsModalOpen(true);
  };

  return (
    <div style={{ backgroundColor: "#F4F4F4" }} className="min-h-screen px-4  py-8">
      <div className="container mx-auto">
        <button
          onClick={() => window.history.back()}
          className="flex items-center rounded-full bg-blue-950 text-white py-2 px-2 mb-4"
        >
          <FaArrowLeft className="mr-2" />
          Go Back
        </button>

        <h2 className="text-3xl font-semibold mb-8 text-black">
          Work From Home Employees Details
        </h2>

        <div className="flex flex-col md:flex-row md:justify-between items-center mb-6">
          {/* Department filter */}
          <div className="mb-6 md:mb-0">
            <label
              htmlFor="departmentFilter"
              className="mr-2 md:mt-5 font-bold text-black"
            >
              Filter by Department:
            </label>
            <select
              id="departmentFilter"
              className="border border-black rounded p-2 font-semibold text-black"
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            >
              <option value="All">All Departments</option>
              {departments.map((department, index) => (
                <option key={index} value={department}>
                  {department}
                </option>
              ))}
            </select>
          </div>

          {/* Search filter */}
          <div className="md:ml-12">
            <label
              htmlFor="departmentFilter"
              className="mr-2 md:mt-5 font-bold text-black"
            >
              Search by Name:
            </label>
            <input
              type="text"
              placeholder="Enter name ..."
              className="border border-black rounded p-2 font-semibold text-black"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        {/* Employee list */}
        <div className="flex justify-between items-center bg-blue-950 p-6 rounded-lg mb-8 overflow-x-auto">
          {/* Table headers */}
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Image
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Name
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Department
          </div>
          
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Start Time
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            End Time
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Total Hours
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Date
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Status
          </div>
          <div className="w-1/5 text-center text-lg font-bold text-white">
            Actions
          </div>
        </div>

        {filteredEmployeesByName.length === 0 ? (
          <div className="text-center text-gray-600">
            No Work from Home employees found.
          </div>
        ) : (
          <>
            {/* Display filtered employees */}
            {displayedEmployees.map((employee) => (
              <div
                key={employee.id}
                className="flex shadow-lg bg-white rounded-full mb-4 transition-transform transform hover:translate-y-1 overflow-x-auto"
              >
                {/* Employee details */}
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <FaUser className="h-8 w-8 text-gray-800" />
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="font-semibold text-md text-gray-800">
                      {employee.name}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="text-gray-800 text-md">
                      {employee.department}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="text-gray-800 text-md">
                      {employee.startTime}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="text-gray-800 text-md">
                      {employee.endTime}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="text-gray-800 text-md">
                      {employee.totalHours}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="text-gray-800 text-md">
                      {employee.date}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  <div className="text-center">
                    <div className="text-md font-bold" style={{ color: '#B09BFD' }}>
                      {employee.status.toUpperCase()}
                    </div>
                  </div>
                </div>
                <div className="w-1/5 p-2 flex justify-center items-center">
                  {/* Update icon button */}
                  <FaEdit
                    className="h-6 w-6 text-gray-800 cursor-pointer"
                    onClick={() => handleUpdateClick(employee)}
                  />
                </div>
              </div>
            ))}

            {/* Pagination */}
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={Math.ceil(
                filteredEmployeesByName.length / employeesPerPage
              )}
              onPageChange={changePage}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </>
        )}
      </div>

      {selectedEmployee && (
        <UpdateAttendance
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          attendanceData={selectedEmployee}
        />
      )}
    </div>
  );
};

export default WorkFromHomeEmployees;
