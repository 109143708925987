import React, { useState } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AttendanceDetails = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [attendanceData, setAttendanceData] = useState({});
  const [error, setError] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const fetchAttendanceDetails = async () => {
    if (!selectedDate) {
      toast.error("Please select a date.");
      return;
    }

    try {
      setIsDataFetched(true);
      const token = localStorage.getItem("token");
      const response = await fetch(
        "https://backendmd.marsbpo.org/api/attendance/getAttendanceDetailsByDate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ date: selectedDate }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch attendance details");
      }

      const data = await response.json();
      setAttendanceData(data.attendanceDetails);
      if (Object.values(data.attendanceDetails).some((arr) => arr.length > 0)) {
        setError("");
      } else {
        setError("No attendance data available for the selected date.");
      }
    } catch (error) {
      console.error("Error fetching attendance details:", error.message);
      toast.error("Error fetching attendance details. Please try again.");
    }
  };

  const exportToExcel = () => {
    if (!attendanceData) {
      toast.error("No attendance data to export.");
      return;
    }

    const workbook = XLSX.utils.book_new();

    Object.keys(attendanceData).forEach((type) => {
      const worksheet = XLSX.utils.json_to_sheet(attendanceData[type]);
      XLSX.utils.book_append_sheet(workbook, worksheet, `${type}Employees`);
    });

    XLSX.writeFile(workbook, `AttendanceDetails_${selectedDate}.xlsx`);
  };

  const deleteAttendance = async () => {
    if (!selectedDate) {
      toast.error("Please select a date to delete attendance.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        "https://backendmd.marsbpo.org/api/attendance/deleteAttendanceByDate",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ date: selectedDate }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete attendance data");
      }

      setAttendanceData({});
      setError("Attendance data deleted successfully.");
      toast.success("Attendance data deleted successfully.");
    } catch (error) {
      console.error("Error deleting attendance data:", error.message);
      toast.error("Error deleting attendance data. Please try again.");
    }
  };

  const getColorClass = (type) => {
    switch (type) {
      case "present":
        return "bg-green-100 text-green-800";
      case "absent":
        return "bg-red-100 text-red-800";
      case "late":
        return "bg-yellow-100 text-yellow-800";
      case "leave":
        return "bg-blue-100 text-blue-800";
      case "Work From Home":
      case "work from home": // Add the status "WFH" here if it's used as well
        return "bg-purple-100 text-purple-800"; // Choose the color you prefer for Work From Home employees
      default:
        return "";
    }
  };

  return (
    <div className="lg:w-3/4 mx-auto p-6 bg-white border-t-4 border-blue-950 rounded-lg shadow-lg mt-8 relative">
      <div className="mb-6 flex flex-col md:flex-row md:items-center md:justify-between">
        <label
          htmlFor="dateInput"
          className="text-lg font-semibold mb-2 md:mb-0 md:text-right md:w-1/4"
        >
          Select Date:
        </label>
        <div className="flex items-center space-y-4 md:space-y-0 md:space-x-4 md:w-3/4">
          <input
            type="date"
            id="dateInput"
            value={selectedDate}
            onChange={handleDateChange}
            className="border p-2 rounded-md flex-grow"
          />
          <div className="flex flex-col md:flex-row">
            <button
              onClick={fetchAttendanceDetails}
              className="px-4 py-2 bg-blue-950 text-white rounded-md mb-2 md:mb-0 md:mr-2"
            >
              Search
            </button>
            <button
              onClick={exportToExcel}
              className="px-4 py-2 bg-green-500 text-white rounded-md mb-2 md:mb-0 md:mr-2"
            >
              Export to Excel
            </button>
            <button
              onClick={deleteAttendance}
              className="px-4 py-2 bg-red-500 text-white rounded-md"
            >
              Delete Attendance
            </button>
          </div>
        </div>
      </div>

      {error && (
        <div className="mt-4 text-red-500">
          <p>{error}</p>
        </div>
      )}

      {isDataFetched && !error && (
        <div>
          {Object.keys(attendanceData).length > 0 ? (
            <div>
              <h2 className="text-2xl font-bold mb-4">
                Attendance Summary for {selectedDate}
              </h2>
              {Object.keys(attendanceData).map((type) => (
                <div key={type} className="mb-4">
                  <h3 className="text-lg font-bold mb-2">
                    {type.charAt(0).toUpperCase() + type.slice(1)} Employees (
                    {attendanceData[type].length})
                  </h3>
                  <table className="border-collapse border border-gray-800 w-full">
                    <thead>
                      <tr>
                        <th className="bg-blue-950 border border-white-800 p-2 text-white ">Employee</th>
                        <th className="bg-blue-950 border border-white-800 p-2 text-white ">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendanceData[type].map((employee) => (
                        <tr
                          key={employee.id}
                          className={`${getColorClass(employee.status.toLowerCase())}`}
                        >
                          <td className="border border-gray-800 p-2">{employee.name}</td>
                          <td className="border border-gray-800 p-2">{employee.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-4 text-red-500">
              <p>No attendance data available for the selected date.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AttendanceDetails;
