import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddPC = () => {
  const [pcName, setPcName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [group, setGroup] = useState("");
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://backendmd.marsbpo.org/api/employees/getAllEmployees', {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setEmployees(response.data);
      } catch (error) {
        console.error('Error fetching employees:', error);
        toast.error('Failed to fetch employees');
      }
    };
    fetchEmployees();
  }, []);

  const handleAddPC = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      await axios.post('https://backendmd.marsbpo.org/api/pc/addpc', 
        { pcName, employeeId, group },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setPcName("");
      setEmployeeId("");
      setGroup("");
      toast.success('PC added successfully');
    } catch (error) {
      console.error('Error adding PC:', error);
      toast.error('Failed to add PC');
    }
  };

  return (
    <div className="max-w-lg mx-auto mt-10">
      <div className="bg-white shadow-md rounded px-8 py-8">
        <h2 className="text-lg font-semibold mb-6">Add a New PC</h2>
        <form onSubmit={handleAddPC}>
          <div className="mb-6">
            <label htmlFor="pcName" className="block text-gray-700 text-sm font-bold mb-2">PC Name:</label>
            <input
              id="pcName"
              type="text"
              value={pcName}
              onChange={(e) => setPcName(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter PC Name"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="employeeSelect" className="block text-gray-700 text-sm font-bold mb-2">Assign to Employee:</label>
            <select
              id="employeeSelect"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select an Employee</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.employeeName}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6">
            <label htmlFor="group" className="block text-gray-700 text-sm font-bold mb-2">Group:</label>
            <input
              id="group"
              type="text"
              value={group}
              onChange={(e) => setGroup(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Group"
            />
          </div>
          <div className="flex items-center justify-end">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Add PC
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddPC;
