import React, { useState } from 'react';
import axios from 'axios';
import ManageGroups from "./ManageGroups";
import AddPC from "./AddPC";
import PcReports from "./PcReports";
import SendNotification from './SendNotification';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationHome = () => {
  const [selectedButton, setSelectedButton] = useState('sendnoti');
  
  return (
    <div className="container mx-auto p-8">
          <ToastContainer />

      <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 mb-4">

        <button
          className={`flex-1 py-2 px-4 rounded-full focus:outline-none ${
            selectedButton === 'sendnoti' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('sendnoti')}
        >
          Send Notification
        </button>
        <button
          className={`flex-1 py-2 px-4 rounded-full focus:outline-none ${
            selectedButton === 'notifygroup' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('notifygroup')}
        >
          Notify Group
        </button>
        <button
          className={`flex-1 py-2 px-4 rounded-full focus:outline-none ${
            selectedButton === 'addpc' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('addpc')}
        >
          Assign Pc's
        </button>
        <button
          className={`flex-1 py-2 px-4 rounded-full focus:outline-none ${
            selectedButton === 'pcreport' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('pcreport')}
        >
          PC Report
        </button>
      </div>

      {selectedButton === 'sendnoti' && <SendNotification />}
      {selectedButton === 'notifygroup' && <ManageGroups />}
      {selectedButton === 'pcreport' && <PcReports />}
      {selectedButton === 'addpc' && <AddPC />}
      </div>
  );
};

export default NotificationHome;
