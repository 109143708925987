import React, { useState, useEffect } from 'react';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import logo from "../../assets/logo.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from "xlsx";

const generatePDFContent = (pcData) => {
  const currentDate = new Date().toLocaleDateString();

  let content = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>PC Reports</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 0;
              }

              .header {
                  text-align: center;
                  margin-bottom: 30px;
              }

              .date {
                  text-align: right;
                  margin-right: 20px;
              }

              table {
                  width: 90%;
                  border-collapse: collapse;
                  margin: 0 auto;
              }

              th, td {
                  padding: 10px;
                  border: 1px solid #ddd;
                  text-align: left;
              }

              th {
                  background-color: #f2f2f2;
                  font-weight: bold;
              }
              .logo {
                width: 100px;
                height: 100px;
                margin-bottom: 20px;
              }
    
          </style>
      </head>
      <body>
          <div class="header">
          <img src="${logo}" alt="Logo" class="logo">
              <h1>PC Reports</h1>
              <p class="date">Date: ${currentDate}</p>
          </div>
          <table>
              <thead>
                  <tr>
                      <th>PC Name</th>
                      <th>Employee</th>
                      <th>PC Id</th>
                      <th>Group</th>
                  </tr>
              </thead>
              <tbody>
  `;

  pcData.forEach(pc => {
    content += `
          <tr>
              <td>${pc.pcName}</td>
              <td>${pc.employeeName}</td>
              <td>${pc.pcId}</td>
              <td>${pc.group}</td>
          </tr>
      `;
  });

  content += `
              </tbody>
          </table>
      </body>
      </html>
  `;

  return content;
};
const PcReports = () => {
  const [pcData, setPcData] = useState([]);
  const [filteredGroup, setFilteredGroup] = useState('');

  useEffect(() => {
    const fetchPcData = async () => {
      try {
        const response = await axios.get('https://backendmd.marsbpo.org/api/pc/getAllPCs');
        setPcData(response.data);
      } catch (error) {
        console.error('Error fetching PC data:', error);
      }
    };
    fetchPcData();
  }, []);

  const handleFilterChange = (e) => {
    setFilteredGroup(e.target.value);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://backendmd.marsbpo.org/api/pc/delete/${id}`);
      // Remove the deleted PC from the state
      setPcData(pcData.filter(pc => pc.pcId !== id));
      toast.success('PC deleted successfully');
    } catch (error) {
      console.error('Error deleting PC:', error);
      toast.error('Failed to delete PC');
    }
  };

  const exportToPDF = async () => {
    try {
      const content = generatePDFContent(pcData);
      await html2pdf().from(content).save('pc_reports.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };

  const exportToExcel = () => {
    if (!pcData.length) {
      toast.error("No PC data to export.");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(pcData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "PC Reports");
    XLSX.writeFile(workbook, 'pc_reports.xlsx');
  };

  // Extract unique group names from pcData
  const uniqueGroups = [...new Set(pcData.map(pc => pc.group))];

  return (
    <div className="container mx-auto mt-10">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-semibold">PC Reports</h1>
        <div className="flex justify-end items-center mt-4">
          <button onClick={exportToPDF} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2">
            Export PDF
          </button>
          <button onClick={exportToExcel} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Export Excel
          </button>
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="groupFilter" className="block text-sm font-medium text-gray-700 mb-2">Filter by Group:</label>
        <select
          id="groupFilter"
          value={filteredGroup}
          onChange={handleFilterChange}
          className="shadow appearance-none border rounded w-half py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">All Groups</option>
          {uniqueGroups.map(group => (
            <option key={group} value={group}>{group}</option>
          ))}
        </select>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">PC Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">PC Id</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Group</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th> {/* Action column */}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {pcData
            .filter(pc => !filteredGroup || pc.group === filteredGroup) // Apply filtering based on selected group
            .map(pc => (
              <tr key={pc.pcId}>
                <td className="px-6 py-4 whitespace-nowrap">{pc.pcName}</td>
                <td className="px-6 py-4 whitespace-nowrap">{pc.employeeName}</td>
                <td className="px-6 py-4 whitespace-nowrap">{pc.pcId}</td>
                <td className="px-6 py-4 whitespace-nowrap">{pc.group}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button onClick={() => handleDelete(pc.pcId)} className="text-red-600 hover:text-red-900">Delete</button> {/* Delete button */}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};

export default PcReports;
