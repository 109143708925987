import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { FaUser, FaArrowLeft } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';

const AllApplicants = () => {
    const location = useLocation();

    const applicantsData = useMemo(() => location.state || { applicants: [] }, [location.state]);

    const [applicants, setApplicants] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const employeesPerPage = 25;

    useEffect(() => {
        if (applicantsData && applicantsData.applicants) {
            setApplicants(applicantsData.applicants);
        }
    }, [applicantsData]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setPageNumber(0); // Reset page number when search query changes
    };

    const filteredApplicants = useMemo(() => {
        return applicants.filter((applicant) =>
            applicant.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [applicants, searchQuery]);

    const startIndex = pageNumber * employeesPerPage;
    const endIndex = Math.min(startIndex + employeesPerPage, filteredApplicants.length);
    const displayedApplicants = filteredApplicants.slice(startIndex, endIndex);

    const pageCount = Math.ceil(filteredApplicants.length / employeesPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'PENDING':
                return '#60A5FA'; // Blue
            case 'REJECTED':
                return '#F87171'; // Red
            case 'ACCEPTED':
                return '#4ADE80'; // Green
            case 'IN REVIEW':
                return '#F59E0B'; // Orange
            default:
                return 'black'; // Default color
        }
    };

    const handleDelete = async (applicantId) => {
        try {
            // Send request to delete the applicant from the database
            await axios.delete(`https://backendmd.marsbpo.org/api/recruitment-forms/deleteApplicant/${applicantId}`);

            // Display a success toast message
            toast.success("Applicant deleted successfully!");

            // Fetch the updated list of applicants after deletion
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://backendmd.marsbpo.org/api/recruitment-forms/all-applicants`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const updatedAcceptedApplicants = response.data.applicants;
            setApplicants(updatedAcceptedApplicants); // Update the state with the new list of applicants
        } catch (error) {
            console.error("Error deleting applicant:", error);
            // Display an error toast message
            toast.error("Error deleting applicant. Please try again later.");
        }
    };

    return (
        <div style={{ backgroundColor: "#F4F4F4" }} className="min-h-screen px-4 py-8">
             <ToastContainer />
            <div className="container mx-auto">
                <button
                    onClick={() => window.history.back()}
                    className="flex items-center rounded-full bg-blue-950 text-white py-2 px-2 mb-4"
                >
                    <FaArrowLeft className="mr-2" />
                    Go Back
                </button>

                <h2 className="text-3xl font-semibold mb-8 text-black">
                    All Applicants Details
                </h2>

                <div className="md:ml-0 mb-8">
                    <label
                        htmlFor="nameFilter"
                        className="mr-2 md:mt-5 font-bold text-black"
                    >
                        Search by Name:
                    </label>
                    <input
                        type="text"
                        placeholder="Enter name ..."
                        className="border border-black rounded p-2 font-semibold text-black"
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full bg-blue-950 text-white rounded-lg mb-8">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border">Name</th>
                                <th className="py-2 px-4 border">Father's Name</th>
                                <th className="py-2 px-4 border">CNIC No</th>
                                <th className="py-2 px-4 border">Address</th>
                                <th className="py-2 px-4 border">City</th>
                                <th className="py-2 px-4 border">Contact No</th>
                                <th className="py-2 px-4 border">Email</th>
                                <th className="py-2 px-4 border">Department</th>
                                <th className="py-2 px-4 border">Job Role</th>
                                <th className="py-2 px-4 border">Experience</th>
                                <th className="py-2 px-4 border">Experience Details</th>
                                <th className="py-2 px-4 border">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedApplicants.length === 0 ? (
                                <tr>
                                    <td className="py-2 px-4 text-center border" colSpan="13">No applicants found.</td>
                                </tr>
                            ) : (
                                displayedApplicants.map((applicant, index) => (
                                    <tr key={index} className="bg-white text-gray-800">
                                        <td className="py-2 px-4 text-center border">{applicant.name}</td>
                                        <td className="py-2 px-4 text-center border">{applicant.fatherName}</td>
                                        <td className="py-2 px-4 text-center border">{applicant.cnicNo}</td>
                                        <td className="py-2 px-4 text-center border">{applicant.address}</td>
                                        <td className="py-2 px-4 text-center border">{applicant.city}</td>
                                        <td className="py-2 px-4 text-center border">{applicant.contactNo}</td>
                                        <td className="py-2 px-4 text-center border">{applicant.email}</td>
                                        <td className="py-2 px-4 text-center border">{applicant.department}</td>
                                        <td className="py-2 px-4 text-center border">{applicant.jobRole}</td>
                                        <td className="py-2 px-4 text-center border">{applicant.experience}</td>
                                        <td className="py-2 px-4 text-center border">{applicant.experienceDetails}</td>
                                        <td
                                            className="py-2 px-4 text-center border"
                                            style={{
                                                color: getStatusColor(applicant.status),
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {applicant.status}
                                        </td>
                                        <td className="py-2 px-4 text-center border">
                                            <button
                                                className="text-white bg-red-600 px-2 py-1 rounded"
                                                onClick={() => handleDelete(applicant.id)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </div>
            </div>
        </div>
    );
};

export default AllApplicants;
